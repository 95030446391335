import { MARKETPLACES } from 'constants/countries'
import { ASIN_REGEX } from '../constants/asin'

export const parseAsin = value => {
  const regex = new RegExp(ASIN_REGEX, 'gi')
  if (regex.test(value)) return value.match(regex)[0]

  return null
}

export const isAsin = value => {
  const exactAsinRegex = new RegExp(`^${ASIN_REGEX}$`, 'gi')
  return exactAsinRegex.test(value)
}

export const parseAsinFromLink = link => {
  const asinRegex = /\/[A-Z0-9]{10}[/|?]*/
  const matches = link.match(asinRegex)
  const asin = matches && matches[0]

  if (asin) {
    return asin.replace(/\//g, '').replace(/\?/g, '')
  }

  return null
}

export const everyIsAsin = values => {
  return Array.isArray(values) && values.length > 0 && values.every(isAsin)
}

export const isAmazonUrl = value => {
  try {
    const parsedUrl = new URL(value)
    return parsedUrl.hostname.includes('.amazon.')
  } catch (e) {
    return false
  }
}

export const parseMarketplaceFromUrl = url => {
  const res = url.match(/amazon.([\w|.]*)/)

  if (!res) {
    return null
  }
  const code = res[1]
  let marketplace = 'us'

  if (code === 'com') {
    return marketplace
  }

  Object.values(MARKETPLACES).map(item => {
    if (item.topDomain === code) {
      marketplace = item.code
    }
    return false
  })

  return marketplace
}

/**
 * Given a country code returns the Amazon host (e.g. https://www.amazon.com, ...co.uk)
 */
export const getAmazonHost = countryCode => {
  const tld = countryCode
    ? Object.values(MARKETPLACES).find(
        o => o.code === countryCode.toLowerCase()
      )?.topDomain
    : undefined

  return `https://www.amazon.${tld || 'com'}`
}

/**
 * Given a nodeId returns the full Amazon category URL
 */
export const getAmazonSubCategoryUrl = nodeId => {
  if (!nodeId) {
    return undefined
  }
  const [country, node] = nodeId?.split('/') || ['', '']
  const host = getAmazonHost(country)
  return `${host}/b?node=${node}`
}

/**
 * Given a country code and category code returns the full Amazon parent category URL
 */
export const getAmazonParentCategoryUrl = (country, code) => {
  if (!country || !code) {
    return undefined
  }
  const host = getAmazonHost(country)
  return `${host}/gp/bestsellers/${code}`
}

/**
 * Given an ASIN and a country code returns the full product Amazon URL
 */
export const amazonUrl = (asin, country) =>
  `${getAmazonHost(country)}/dp/${asin}`
